import * as React from "react";
import Layout from "../components/Layout";
import {Col, List, Row} from "antd";
import { graphql } from "gatsby";
import CardContainer from "../components/CardContainer";
import styled from "styled-components";
import Card from "../components/Card";
import { columnProps, getPostsFromQuery, getReadableDate } from "../helpers";
import { Text } from "../components/Text";
import { LinkWithArrow } from "../components/LinkWithArrow";
import {Navigation} from "../components/Navigation";
import ListContainer from "../components/ListContainer";
import Link from "gatsby-link";

const Wrapper = styled.div`
  .color-background {
    height: 100%;
  }
  .container:last-child {
    padding-bottom: 5rem;
  }
`;

const ListRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 650px) {
    flex-direction: column;
  }

  .title {
    font-weight: 500;
    font-size: 1rem;
  }
  .date {
    @media (min-width: 650px) {
      margin-left: auto;
      padding-left: 1rem;
    }
    @media (max-width: 650px) {
      padding-top: 1rem;
    }
    white-space: nowrap;
    color: hsl(0, 0%, 70%);
  }
`;

const StyledLink = styled(Link)`
  color: rgba(0, 0, 0, 0.85);
  transition: all 0.2s;
  &:hover {
    color: hsl(0, 0%, 40%);
  }
`;

const Title = styled.div`
  font-size: 19px;
  font-weight: 500;
  padding-bottom: 1rem;
`;

const Header = styled.h1`
  margin-top: 2rem;
  margin-bottom: 0rem;
`;


const Blog = ({ data }) => {
  const posts = getPostsFromQuery(data);
  return (
    <Layout>
      <Wrapper>
        <div className="white-background">
          <Row className="content-row">
            <Col {...columnProps}>
              <Navigation />
              <h1>My primary question</h1>
              <Title>How can we create truly intelligent software agents?</Title>
              <p>I spend most of my time working on this question. If you'd like to help, <a href={"https://generallyintelligent.ai/#open-positions"}>we're hiring</a> :-)</p>
              <Header>Other open questions</Header>
              <List
                itemLayout="horizontal"
                dataSource={posts.filter((post) => post.slug.indexOf("/open/") !== -1)}
                renderItem={(item) => (
                  <List.Item>
                    <ListRow>
                      <span className="title">
                        <a href={item.slug}><Title>{item.title}</Title></a>
                      </span>
                      {/*<div className="date">{getReadableDate(item.date)}</div>*/}
                    </ListRow>
                  </List.Item>
                )}
              />
              <Header>Answered questions</Header>
              <CardContainer
                className="container"
                items={posts.filter((post) => post.slug.indexOf("/closed/") !== -1)}
                minWidth={"100%"}
                render={(item) => (
                  <Card
                    key={item.key}
                    {...item}
                    title={item.title}
                    description={item.snippet || item.excerpt}
                    tags={[getReadableDate(item.date)]}
                    link={item.slug}
                    isClickable={true}
                    footer={
                      <LinkWithArrow to={item.slug}>Read more</LinkWithArrow>
                    }
                  />
                )}
              />
            </Col>
          </Row>
        </div>
      </Wrapper>
    </Layout>
  );
};

export const query = graphql`
  query {
    allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      edges {
        node {
          id
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            date
            author
            tags
            snippet
            title
          }
          fileAbsolutePath
        }
      }
    }
  }
`;

export default Blog;
